import { SET_CONTACT_LIST, SET_EXPORT_CONTACT_LIST, SET_USERS_COUNT } from "./constants";

const initialState = {
    contactList: [],
    contactExportList: [],
    contactListCount: null,
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_CONTACT_LIST: {
            // console.log("contactList: ", action.payload);
            const contacts = action.payload;
            const contactList = contacts.value;
            // console.log("contactList: ", contactList);
            const contactListCount = contacts['@odata.count'];
            return {
                ...localState,
                contactList: contactList,
                contactListCount: contactListCount,

            }
        }
        case SET_EXPORT_CONTACT_LIST: {
            // console.log("contactList: ", action.payload);
            // const contacts = action.payload;
            const contactList = action.payload;
            return {
                ...localState,
                contactExportList: contactList,

            }
        }
        default: {
            return localState;
        }
    }
};
