/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Label,
    Form,
    FormGroup,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Input,
    CardTitle,
} from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import VWMessage from '../../components/VWMessage/VWMessage';
import VWButton from '../../components/VWButton/VWButton';
import { clearLoginUserError, userLogin } from '../../store/Auth/Login/actions';
import { connect } from 'react-redux';
import FormValidator from "../../helper/formValidator"
import { clearForm, updateForm } from '../../store/Form/actions';

const mapStateToProps = (state) => ({
    form: state.form,
    isAuth: state.login.isAuth,
    isPending: state.login.isPending,
    errorMessage: state.login.errorMessage,
})

const mapDispatchToProps = (dispatch) => ({
    userLoginAction: (userName, Password) => dispatch(userLogin(userName, Password)),
    clearLoginUserError: () => dispatch(clearLoginUserError()),
    updateFormAction: (data) => dispatch(updateForm(data)),
    clearFormAction: () => dispatch(clearForm()),
})

const Login = ({ form, updateFormAction, userLoginAction, isAuth, isPending, errorMessage, clearFormAction, clearLoginUserError }) => {
    const [login, setLogin] = useState()

    useEffect(() => {
        clearLoginUserError();
        clearFormAction();
    }, [])

    const validateOnChange = (event) => {
        const input = event.target;
        const formData = input.form;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);
        setLogin({
            [input.name]: value,
        })
        updateFormAction({
            [formData.name]: {
                ...form[formData.name],
                [input.name]: value,
                errors: {
                    ...(form[formData.name] && form[formData.name].errors),
                    [input.name]: result,
                },
            },
        });
    };
    // const handleNextField = (e) => {
    //     if (!(login && login.pasword)) {
    //         if (e.key === "Enter") {
    //             const nextfield = document.querySelector(
    //                 `input[name=password]`
    //             );
    //             nextfield.focus();
    //             console.log("It's moving", nextfield)
    //         }
    //     }
    // }
    const onSubmit = (e) => {
        // setLogin(true)
        e.preventDefault();
        // if ((login && login.userName) && (login && login.password))
        userLoginAction(form.formLogin && form.formLogin.userName, form.formLogin && form.formLogin.password);
    };

    // /* Simplify error check */
    const hasError = (formName, inputName, method) => {
        return (
            form[formName] &&
            form[formName].errors &&
            form[formName].errors[inputName] &&
            form[formName].errors[inputName][method]
        );
    };
    //eslint-disable-next-line
    console.log("errorMessage:", errorMessage)

    return isAuth ? (
        <Navigate to="/dashboard" />
    ) : (
        <div className="loginBox fs-6">
            <Container fluid style={{ backgroundColor: '#ebedf0' }}>
                <Row className="justify-content-center align-items-center vh-100">

                    <Col lg="12" className="loginContainer">
                        {/* <AuthLogo /> */}
                        <h2 className='text-center p-4' >Mass Mailer Portal</h2>
                        <Card>
                            <CardBody className="p-4 m-1">
                                <div className="text-center">
                                    <CardTitle className="mt-2 fs-4">
                                        Login
                                    </CardTitle>
                                </div>
                                {errorMessage && (
                                    <VWMessage
                                        type="danger"
                                        defaultMessage={errorMessage}
                                        baseClassName="alert"
                                    ></VWMessage>
                                )}
                                <Form name="formLogin">
                                    <FormGroup>
                                        <Label htmlFor="exampleEmail12">Email</Label>
                                        <Input
                                            type="text"
                                            name="userName"
                                            placeholder="Enter Email"
                                            invalid={hasError('formLogin', 'userName', 'required')}
                                            onChange={validateOnChange}
                                            // onKeyPress={handleNextField}
                                            data-validate='["required"]'
                                            value={
                                                form && form.formLogin && form.formLogin.userName
                                                    ? form.formLogin.userName
                                                    : ''
                                            }
                                        // autoComplete="userName"
                                        />
                                        {hasError('formLogin', 'userName', 'required') && (
                                            <span className="invalid-feedback">Email is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="examplePassword2">Password</Label>
                                        <Input
                                            type="password"
                                            id="id-password"
                                            name="password"
                                            className="border-right-0"
                                            placeholder="Enter Password"
                                            invalid={hasError('formLogin', 'password', 'required')}
                                            onChange={validateOnChange}
                                            data-validate='["required"]'
                                            value={
                                                form && form.formLogin && form.formLogin.password
                                                    ? form.formLogin.password
                                                    : ''
                                            }
                                        />
                                        <span className="invalid-feedback">Password is required</span>
                                    </FormGroup>
                                    <FormGroup className="form-check d-flex" inline>

                                        <Link className="text-success ms-auto text-decoration-none" to="/recoverpwd">
                                            <small>Forgot Password?</small>
                                        </Link>
                                    </FormGroup>
                                    <FormGroup className='d-flex justify-content-center'>
                                        <VWButton
                                            messageKey="loginButton"
                                            defaultMessage="Login"
                                            isLoading={isPending}
                                            isDisabled={isPending}
                                            buttonType="success"
                                            buttonLength="full"
                                            baseClassName="btn"
                                            className="mt-3"
                                            onClick={onSubmit}
                                        ></VWButton>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Login);
