import React from "react";
import { Container, Row, Col, Card } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="p-0">
                <Container className="position-absolute bottom-0 start-0" fluid={true}>
                    <Card style={{ backgroundColor: "#ebedf0" }} className="p-2 text-center">
                        <Row>
                            <Col sm={12}>{new Date().getFullYear()} © <a href="https://www.visionwaretechnologies.com" target="_blank" className="border-bottom border-primary ">Visionware Technologies.</a></Col>
                        </Row>
                    </Card>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
