import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
// import { USER_LOGOUT } from "./auth/login/actionTypes";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
// const middlewares = [thunkMiddleware, sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Retrieve any previous state from sessionStorage
const persistedState = (() => {
  try {
    const state = sessionStorage.getItem("reduxState");
    return state ? JSON.parse(state) : {};
  } catch (e) {
    console.warn("Error parsing sessionStorage:", e);
    return {};
  }
})();

//ENV files
let middleWares;

switch (process.env.NODE_ENV) {
  case 'development':
    middleWares = composeWithDevTools(thunkMiddleware);
    break;
  case 'production':
  default:
    middleWares = thunkMiddleware;
}
const middlewares = [middleWares, sagaMiddleware];

//Reducer
const appReducer = (state, action) => {

  if (action.type === "USER_LOGOUT") {
    sessionStorage.clear();
    state = undefined;
    sessionStorage.removeItem("reduxState")
  }
  return rootReducer(state, action);
};

export function configureStore() {
  const store = createStore(
    appReducer,
    persistedState, // Use previous state from sessionStorage, if any
    composeEnhancers(applyMiddleware(...middlewares))
  );

  // Save the state to sessionStorage on any change
  store.subscribe(() => {
    sessionStorage.setItem("reduxState", JSON.stringify(store.getState()));
  });

  sagaMiddleware.run(rootSaga);

  // if (!store.getState().token && typeof store.getState().token === "undefined") {
  //   window.location.href = "/login"; // Redirect to login page
  // }

  return store;
}
