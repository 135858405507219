/* eslint-disable import/prefer-default-export */
import agent from '../../services/agent';
import { clearForm } from '../../Form/actions';
import { showSnackbarStatus } from '../../snackbar/actions';
// import { userLogin } from '../login/action';
import {
    CLEAR_RESET_PASSWORD_ERROR,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_PENDING,
    RESET_PASSWORD_PENDING_SUCCESS,
} from './constants';

export const resetPasswordError = (errorMessage) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { errorMessage },
});

export const resetPasswordPending = () => ({
    type: RESET_PASSWORD_PENDING,
});

export const resetPasswordPendingSuccess = () => ({
    type: RESET_PASSWORD_PENDING_SUCCESS,
});

export const clearResetPasswordError = () => ({
    type: CLEAR_RESET_PASSWORD_ERROR,
});

export const resetPassword = (code, password, confirmPassword, email) => (dispatch) => {
    // const currentState = getState();
    // const { formResetPassword } = currentState && currentState.form;
    // console.log("formResetPassword", formResetPassword)
    console.log("code", code);
    dispatch(clearResetPasswordError());
    dispatch(resetPasswordPending());
    agent.Auth.resetPassword(code, password, confirmPassword, email).then((resp) => {
        console.log("resp:", resp);
        dispatch(showSnackbarStatus('PASSWORD SUCCESSFULLY RESETED'));
        dispatch(resetPasswordPendingSuccess());
        // dispatch(clearForm());
    })
        .catch((err) => {
            if (err && err.response) {
                const { text } = err && err.response;
                console.log("text:", text)
                dispatch(resetPasswordError(text));
                dispatch(showSnackbarStatus(text));
                console.log('reset password error', err && err.response);
                dispatch(resetPasswordError(text));
                // dispatch(clearForm());

            }
            dispatch(showSnackbarStatus('INVALID EMAIL OR PASSWORD '))
        })
};
