import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Button, Card, CardText, List } from "reactstrap";

const Home = () => {
    const [isAuth, setIsAuth] = useState(false)
    const handleLogin = () => {
        setIsAuth(true)
    }

    return (
        <>
            {isAuth ? <Navigate to="//localhost:3000/massmailer/dashboard" /> :
                <div>
                    {/* <div className="p-4 position-absolute top-0 end-0"><Button onClick={handleLogin} color="success">Login</Button></div> */}
                    <div style={{ paddingTop: "10%" }} >
                        <div className="p-4 text-center">
                            <h2>Mass Mailer Portal</h2>
                        </div>
                    </div>
                    {/* <div style={{ position: "fixed", bottom: "5vh", width: "100vw" }}>
                        <Card style={{ minHeight: "150px" }}>
                            <CardText>
                                We are introducing our new product.<strong>Unified Portal</strong>
                                <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "100px" }}>
                                    <div style={{ display: "flex" }} >
                                        <List>
                                            <li>Admin</li>
                                            <li>Employee</li>
                                            <li>Timesheet</li>
                                        </List>
                                        <List>
                                            <li>Leavet Tracker</li>
                                            <li>Project</li>
                                            <li>Vision Docs</li>
                                        </List>
                                    </div>
                                    <div className="p-4 text-center">
                                        <p>Try Now</p>
                                        <Button>Register</Button>
                                    </div>
                                </div>
                            </CardText>
                        </Card>
                    </div> */}
                </div>}
        </>
    )
}

export default Home;