import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Row } from "reactstrap";
import { getUsersCount } from "../../store/Dashboard/actions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUsers } from '@fortawesome/free-solid-svg-icons'

const mapStateToProps = (state) => ({
    profile: state.login.profile,
    totalUsers: state.dashboard.totalUsers,
    subscriberCounts: state.dashboard.subscriberCounts,
    unsubscriberCounts: state.dashboard.unsubscriberCounts,
})

const mapDispatchToProps = (dispatch) => ({
    usersCount: (orgName) => dispatch(getUsersCount(orgName))
})

const Dashboard = (props) => {
    const orgName = props.profile && props.profile.orgName;
    const navigate = useNavigate();
    const [filterParams, setFilterParams] = useSearchParams()
    useEffect(() => {
        props.usersCount(orgName)
    }, [])

    const handleClickAll = () => {
        navigate('/contactlist')
    }

    const handleClickSubscribe = () => {
        setFilterParams({
            filter: "optin"
        })
        const path = window.location.search;
        console.log("path:", path);
        const params = new URLSearchParams(path);
        console.log("params:", params);
        const filter = params.get('filter')
        console.log("filter: ", filter);
        navigate(`/contactlist?filter=${filter}`)
    }
    const handleClickUnsubscribe = () => {
        setFilterParams({
            filter: "optout"
        })
        const path = window.location.search;
        console.log("path:", path);
        const params = new URLSearchParams(path);
        console.log("params:", params);
        const filter = params.get('filter')
        console.log("filter: ", filter);
        navigate(`/contactlist?filter=${filter}`)
    }
    return (
        <>
            <div className="p-4 d-flex justify-content-center gap-4 fw-bold text-light" style={{
            }}>
                <Card
                    color="primary"
                    style={{ minWidth: "30%" }}
                    className="btn text-light"
                    onClick={handleClickAll}
                >
                    <div className="py-3 text-center fs-3"> {props.totalUsers || 0}</div>
                    <div className=" text-center fs-5">Users</div>
                </Card>
                <Card
                    color="primary"
                    style={{ minWidth: "30%" }}
                    className="btn text-light"
                    onClick={handleClickSubscribe}
                >
                    <div className="py-3 text-center fs-3"> {props.subscriberCounts || 0}</div>
                    <div className=" text-center fs-5">Subscribed Users</div>
                </Card>
                <Card
                    color="primary"
                    style={{ minWidth: "30%" }}
                    className="btn text-light"
                    onClick={handleClickUnsubscribe}
                >
                    <div className="py-3 text-center fs-3"> {props.unsubscriberCounts || 0}</div>
                    <div className=" text-center fs-5">Unsubscribed Users</div>
                </Card>
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);