import moment from "moment";
// eslint-disable-next-line
export const getToken = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  // console.log("accessToken:", accessToken)
  const expiresInSec = sessionStorage.getItem('expiresInSec');
  // console.log("expiresInSec:", expiresInSec)
  return new Promise((resolve) => {
    const diff = moment.unix(Number(expiresInSec)).diff(moment(), 'minutes');
    // console.log(moment.unix(Number(expiresInSec)))
    // console.log("diff", diff)
    if (accessToken && diff > 5) {
      // console.log("accessToken", accessToken)
      // console.log("accessToken && diff >5:", accessToken && diff > 5)
      resolve(accessToken);
    } else {
      // console.log('getToken ', diff);
      // eslint-disable-next-line no-unused-expressions
      // console.log("accessToken:", accessToken)
      resolve(accessToken);
    }
  });
};

export const getPage = (totalItems, currentPage, pageSize) => {
  // console.log('totalitems', totalItems)
  // console.log('currentpage', currentPage)
  // console.log('pagesize', pageSize)

  currentPage = currentPage || 1;
  // console.log("currentPage:", currentPage)
  pageSize = pageSize || 10;
  // console.log("pageSize", pageSize)
  const totalPages = Math.ceil(totalItems / pageSize);
  // console.log("totalpages:", totalPages)
  let startPage;
  let endPage;
  if (totalPages <= 10) {
    startPage = 1;
    endPage = totalPages;
    // console.log("endPage", endPage)
  }
  else {
    // eslint-disable-next-line
    if (currentPage <= 6) {   // eslint-disable-next-line
      startPage = 1;
      endPage = 10;
    }
    else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
      // console.log("startPage:", startPage);
      // console.log("endPage:", endPage);
    }
    else {
      startPage = currentPage - 5;
      endPage = currentPage + 4
      // console.log("startPage:", startPage)
      // console.log("endPage:", endPage)
    }
  }
  const startIndex = (currentPage - 1) * pageSize;
  // console.log("startIndex", startIndex);
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)
  // console.log(endIndex)
  const pages = [...Array(endPage + 1 - startPage).keys()].map((i) => startPage + i);
  // console.log("pages:", pages)
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    pages
  }

}