import { connect } from "react-redux";
import { useEffect } from "react";
// import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle, Col, Table } from "reactstrap";
import { getUserProfile } from "../../store/UserProfile/actions";




const mapStateToProps = (state) => ({
    userProfile: state.profile.userProfile
})

const mapDispatchToProps = (dispatch) => ({
    getUserProfile: () => dispatch(getUserProfile()),
})

const UserProfile = (props) => {
    useEffect(() => {
        props.getUserProfile();
    }, [])
    console.log("User Profile:", props.userProfile)
    return (
        <div className="p-4">
            <Card>
                <CardBody>
                    <CardTitle className="d-flex justify-content-between align-items-center fs-4">User Details

                    </CardTitle>
                </CardBody>
            </Card>

            <Card>
                <Col >
                    <CardTitle>

                        <Table striped size="sm" style={{ padding: "75px" }}>
                            <tbody>
                                <tr> {/*eslint-disable-next-line */}
                                    <th><h4>{props.userProfile && props.userProfile.displayName}</h4></th>
                                </tr>
                            </tbody>
                        </Table>
                    </CardTitle>

                    <CardBody>
                        <Table bordered striped >
                            <tbody>
                                <tr>
                                    <th scope="row">Username</th>
                                    <td>{props.userProfile && props.userProfile.displayName}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Email</th>
                                    <td>{props.userProfile && props.userProfile.email}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Organization Name</th>
                                    <td>{props.userProfile && props.userProfile.orgName}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Role</th>
                                    <td>{props.userProfile && props.userProfile.role}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Enterprise Name</th>
                                    <td>{props.userProfile && props.userProfile.enterpriseName}</td>
                                </tr>

                            </tbody>
                        </Table>
                    </CardBody>
                </Col>
            </Card>


        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);