import agent from "../services/agent"
import { SET_EMAIL, SET_SUBSCRIBER_DETAILS, SET_SUBSCRIBE_STATUS } from "./constants"

export const setEmail = mail => ({
    type: SET_EMAIL,
    payload: mail,
})

export const setSubscribeStatus = subscribeStatus => ({
    type: SET_SUBSCRIBE_STATUS,
    payload: subscribeStatus,
})

export const setSubscribersDetails = subscriber => ({
    type: SET_SUBSCRIBER_DETAILS,
    payload: subscriber,
})

// export const getSubscriptionStatus = (id) => (dispatch) => {
//     agent.Auth.getSubscriptionStatus()
//         .then((status) => {
//             console.log("status: ", status)
//             const subscriber = status.value.filter((i) => i.Id === id)
//             console.log("subscriber: ", subscriber)
//             dispatch(setSubscribersDetails(subscriber))
//         })
// }
export const getEmail = (id) => (dispatch) => {
    agent.Auth.getEmail(id)
        .then((mail) => {
            console.log("mail: ", mail)
            dispatch(setEmail(mail))
        })
        .catch((err) => {
            console.log("error: ", err.status)
            if (err.status === 404) {
                dispatch(setSubscribersDetails())
            }
        })
}

export const setUnsubscribe = (id) => (dispatch) => {
    agent.Auth.setUnsubscribe(id)
        .then((subscribeStatus) => {
            console.log("response: ", subscribeStatus)
            dispatch(setSubscribeStatus(subscribeStatus))
        })
}