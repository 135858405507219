import moment from 'moment';
import agent from "../../services/agent";
import { CLEAR_LOGIN_ERROR, LOGIN_ERROR, LOGIN_PENDING, LOGIN_PENDING_ERROR, LOGIN_PENDING_SUCCESS, LOGIN_SUCCESS, SET_LOGGEDIN_PROFILE, USER_LOGOUT } from "./constants";
import { showSnackbarStatus } from '../../snackbar/actions';


export const loginUserPending = () => ({
    type: LOGIN_PENDING,
});

export const clearLoginUserError = () => ({
    type: CLEAR_LOGIN_ERROR,
});

export const loginUserPendingSuccess = () => ({
    type: LOGIN_PENDING_SUCCESS,
});

const setLoggedInProfile = (currentProfile) => ({
    type: SET_LOGGEDIN_PROFILE,
    payload: currentProfile,
});

export const loginUserSuccess = (email, token, expires) => ({
    type: LOGIN_SUCCESS,
    payload: { email, token, expires },
});

export const loginUserPendingError = () => ({
    type: LOGIN_PENDING_ERROR,
});

export const loginUserError = (errorMessage) => ({
    type: LOGIN_ERROR,
    payload: { errorMessage },
});

export const setTokenDetails = (authData) => {
    const { accessToken, expiresInSec, tokenType } = authData;
    console.log("Expires In Sec", expiresInSec)
    const expires = moment().unix() + expiresInSec;
    sessionStorage.setItem('accessToken', accessToken);
    // set the expired time as (now + value from server)
    sessionStorage.setItem('expiresInSec', expires);
    sessionStorage.setItem('tokenType', tokenType);
};

export const userLogin = (userName, password) => (dispatch) => {
    dispatch(loginUserPending());
    dispatch(clearLoginUserError());
    agent.Auth.login(userName, password)
        .then((authData) => {
            console.log("authData: ", authData);
            setTokenDetails(authData);
            const { accessToken, expiresInSec } = authData;
            console.log("access_token:", accessToken)
            agent.Auth.getCurrentUser(accessToken)
                .then((currentUser) => {
                    console.log("currentUser:", currentUser);
                    console.log("email:", currentUser.email);
                    console.log("expiresInSec:", authData.expiresInSec);
                    dispatch(loginUserPendingSuccess());
                    dispatch(setLoggedInProfile(currentUser));
                    dispatch(loginUserSuccess(currentUser.email, accessToken, expiresInSec));
                })
                .catch((err) => {
                    // dispatch(loginUserPendingError());
                    console.log('Get Current User server error', err);
                });
        })
        .catch(() => {
            dispatch(loginUserPendingError());
            dispatch(loginUserError("Invalid Email or Password"));
            dispatch(showSnackbarStatus("INVALID EMAIL OR PASSWORD"));
        });
}

export const logoutUser = () => (dispatch) => {
    dispatch({ type: USER_LOGOUT });
};