import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardTitle, Input, Table } from "reactstrap";
import { exportContactList, getContactList } from "../../store/ContactList/actions";
import VWPagination from "../../components/vwPagination/vwPagination";
import { getPage } from "../../helper/common";
import { CONTACT_EXPORT_HEADERS } from "../../store/ContactList/constants";
import * as FileSaver from "file-saver";
import *  as XLSX from "xlsx";
import { useSearchParams } from "react-router-dom";

const mapStateToProps = (state) => ({
    contactListCount: state.contacts.contactListCount,
    contactList: state.contacts.contactList,
    contactExportList: state.contacts.contactExportList,
    profile: state.login.profile,
})

const mapDispatchToProps = (dispacth) => ({
    getContactList: (page, pageSize, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus) =>
        dispacth(getContactList(page, pageSize, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus)),
    exportContactList: (orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus) =>
        dispacth(exportContactList(orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus))
})

const adjustColumnWidth = (ws, width) => {
    const colInfo = ws['!cols'] || [];
    for (let i = 0; i < width.length; i++) {
        colInfo[i] = { wch: width[i] };
    }
    ws['!cols'] = colInfo;
};

const ContactList = (props) => {
    const [pageSize] = useState(10);
    const [pager, setPager] = useState({ currentPage: 1 });
    const [filter, setFilter] = useState({});
    // const [subscriptionStatusFilter, setsubscriptionStatusFilter] = useState()
    const [filterParams, setFilterParams] = useSearchParams()
    //-----------------------------Filter------------------------------------------------

    const path = window.location.search;
    const params = new URLSearchParams(path);
    const paramsFilterValue = params.get('filter');
    console.log("paramsFilterValue: ", paramsFilterValue)
    const paramsSubscriptionStatus = paramsFilterValue === "optin" || paramsFilterValue === "optout" ? paramsFilterValue : null;
    console.log("paramsSubscriptionStatus: ", paramsSubscriptionStatus)
    useEffect(() => {
        setFilter({
            SubscriptionStatus: paramsSubscriptionStatus
        })
    }, [])

    useEffect(() => {
        if (!paramsSubscriptionStatus) {
            handleSearchClick();
        }
    }, [filter.SubscriptionStatus])

    const buildQueryId = () => {
        const { Id } = filter;
        let searchId = '';
        if (Id) {
            searchId += `${Id}`;
        }
        return searchId
    }

    const buildQueryEmail = () => {
        const { Email } = filter;
        let searchEmail = '';
        if (Email) {
            searchEmail += `${Email}`;
        }
        return searchEmail
    }

    const buildQueryOrgName = () => {
        const { OrgName } = filter;
        let searchOrgName = '';
        if (OrgName) {
            searchOrgName += `${OrgName}`;
        }
        return searchOrgName
    }

    const buildQuerySubscriptionStatus = () => {
        const { SubscriptionStatus } = filter;
        let searchSubscriptionStatus = '';
        if (SubscriptionStatus) {
            searchSubscriptionStatus += `${SubscriptionStatus}`;
        }
        return searchSubscriptionStatus
    }

    const searchInputValidation = (e) => {
        // console.log("value: ", e.target.value);
        const input = e.target;
        const value = input.value;
        setFilter({
            ...filter,
            [input.name]: value
        })
        setFilterParams({
            filter: [input.name]
        })
    }

    const handleSearchClick = () => {
        setPage();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchClick();
        }
    }
    //----------------------------Pagination----------------------------------------------

    useEffect(() => {
        const currentPager = getPage(props.contactListCount, 1, pageSize);
        console.log("currentPager:", currentPager);
        setPager(currentPager)
    }, [props.contactListCount])

    const setPage = (page = 1) => {
        // console.log("pageSize : ", pageSize);
        // console.log("buildQuerySubscriptionStatus : ", buildQuerySubscriptionStatus());
        const orgName = props.profile && props.profile.orgName;
        props.getContactList(page, pageSize, orgName, buildQueryId(), buildQueryEmail(), buildQueryOrgName(), buildQuerySubscriptionStatus(), sortDirection, sortOrder, paramsSubscriptionStatus);
        console.log('page', page)
        setPager(prevPager => {
            const totalPages = Math.ceil(props.contactListCount / pageSize);
            const visiblePages = 10;
            const pageOffset = Math.max(0, Math.floor((page - 1) / visiblePages) * visiblePages);
            const newPages = [];
            for (let i = pageOffset + 1; i <= Math.min(totalPages, pageOffset + visiblePages); i++) {
                newPages.push(i);
            }
            return {
                ...prevPager,
                currentPage: page,
                totalPages,
                pages: newPages,
                pageSize
            };
        });
    }

    useEffect(() => {
        const currentPageNumber = pager && pager.currentPage
        console.log('currenPageNumber:', currentPageNumber);
        setPage(currentPageNumber)
        // setFilterParams("")
    }, [])

    // ----------------Sorting---------------------------------------------------------------------

    const [sortDirection, setSortDirection] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);

    const handleSort = (column) => {
        console.log("column: ", column)
        if (sortOrder === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortOrder(column);
            setSortDirection('asc');
        }
    };

    useEffect(() => {
        console.log("sortOrder:", sortOrder)
        console.log("sortDirection:", sortDirection)
        console.log("sortOrder && sortDirection: ", sortOrder && sortDirection)
        if (sortOrder && sortDirection) {
            setPage();
            exportContactDetails();
        }
    }, [sortOrder && sortDirection])

    //-----------------------------Exports------------------------------------------------------------------------
    useEffect(() => {
        exportContactDetails();
    }, [props.contactListCount])

    const exportContactDetails = () => {
        const orgName = props.profile && props.profile.orgName;
        props.exportContactList(orgName, buildQueryId(), buildQueryEmail(), buildQueryOrgName(), buildQuerySubscriptionStatus(), sortDirection, sortOrder, paramsSubscriptionStatus);

    }
    const handleExports = () => {
        const contactListsExported = [];
        props.contactExportList.forEach((element) => {
            contactListsExported.push({
                // Id: element.id || "NA",
                Email: element.email || "NA",
                OrgName: element.orgName || "NA",
                SubscriptionStatus: element.subscriptionStatus || "NA"
            });
        });
        // console.log(customerLists)

        const wsa = XLSX.utils.aoa_to_sheet([CONTACT_EXPORT_HEADERS]);
        const ws: XLSX.WorkSheet = XLSX.utils.sheet_add_json(
            wsa,
            contactListsExported,
            { skipHeader: true, origin: "A2" }
        );
        adjustColumnWidth(ws, [40, 30, 40]);
        const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: ["data"] };
        wb.Sheets.data = ws;
        const excelBuffer: any = XLSX.write(wb, {
            bookType: "biff8",
            type: "array",
        });

        // type ColInfo = {
        //     wpx?: "500px"
        // }

        const data: Blob = new Blob([excelBuffer]);
        FileSaver.saveAs(data, "contacts.xls");
    }
    console.log("subscription Status: ", filter.SubscriptionStatus)
    console.log("props.contactList: ", props.contactList)

    return (
        <>
            <div className="vh-2 p-4">
                <Card >
                    <div style={{ zIndex: "1" }} >
                        <CardTitle
                            tag="h2"
                            className="border-bottom px-3 py-3 mb-0 d-flex justify-content-between align-items-center"
                        >
                            Contacts
                            <div className="content-heading">
                                <div className="ml-auto">
                                    <Button color="success" onClick={handleExports}>
                                        Export
                                    </Button>
                                </div>
                            </div>
                        </CardTitle>
                        <CardBody>
                            <div style={{
                                maxHeight: "500px", overflowX: "auto"
                            }}>
                                <Table className="p-4" >
                                    <thead>
                                        <tr className="sticky-top">
                                            {/* <th onClick={() => handleSort('Id')}><strong>Id</strong>
                                                {sortOrder === 'Id' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th> */}
                                            <th onClick={() => handleSort('Email')}><strong>Username</strong>
                                                {sortOrder === 'Email' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('OrgName')}><strong>OrgName</strong>
                                                {sortOrder === 'OrgName' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>
                                            <th onClick={() => handleSort('SubscriptionStatus')}><strong>Subscription Status</strong>
                                                {sortOrder === 'SubscriptionStatus' && (
                                                    <i className={`fa fa-arrow-${sortDirection === 'asc' ? 'up' : 'down'}`} />
                                                )}</th>

                                        </tr>
                                        <tr style={{ backgroundColor: "white" }}>
                                            {/* <th><Input type='text' placeholder='Search' name='Id'
                                                onChange={searchInputValidation} value={filter && filter.Id} onKeyPress={handleKeyPress}
                                            /></th> */}
                                            <th><Input type='text' placeholder='Search' name='Email'
                                                onChange={searchInputValidation} value={filter && filter.Email} onKeyPress={handleKeyPress}
                                            /></th>
                                            <th><Input type='text' placeholder='Search' name='OrgName'
                                                onChange={searchInputValidation} value={filter && filter.OrgName} onKeyPress={handleKeyPress}
                                            /></th>
                                            {/* <th><Input type='text' placeholder='Search' name='SubscriptionStatus'
                                                onChange={searchInputValidation} value={filter && filter.SubscriptionStatus} onKeyPress={handleKeyPress}
                                            /></th> */}
                                            <th>
                                                <select
                                                    // style={{ width: '100px' }}
                                                    className="form-control"
                                                    name="SubscriptionStatus"
                                                    onChange={searchInputValidation}
                                                    value={filter && filter.SubscriptionStatus}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="optin">OPTIN</option>
                                                    <option value="optout">OPTOUT</option>
                                                </select>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.contactList && props.contactList.map((i, index) =>
                                            <tr className="table-light">
                                                {/* <td>{i.Id}</td> */}
                                                <td>{i.Email}</td>
                                                <td>{i.OrgName}</td>
                                                <td>{i.SubscriptionStatus}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </div>{props.contactListCount > 0 ?
                        <VWPagination pager={pager} setPage={setPage} ></VWPagination> : ""}
                </Card>
            </div >
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);