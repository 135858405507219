import { getToken } from "../../helper/common"
import agent from "../services/agent"
import { SET_USERS_COUNT } from "./constants"

export const setUsersCount = (count) => ({
    type: SET_USERS_COUNT,
    payload: { count }
})
export const getUsersCount = (orgName) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        // console.log("accessToken: ", accessToken)
        agent.Auth.getUsersCount(accessToken)
            .then((counts) => {
                // console.log("Counts: ", counts)
                const orgCount = counts && counts.filter((i) => i.orgName === orgName);
                // console.log("orgCount: ", orgCount);
                const count = orgCount.length > 0 ? orgCount[0] : orgCount;
                // console.log("count: ", count)
                dispatch(setUsersCount(count));
            })
    })
}