import { SET_EMAIL, SET_SUBSCRIBER_DETAILS, SET_SUBSCRIBE_STATUS } from "./constants";

const initialState = {
    email: null,
    id: null,
    subscriptionStatus: null,
    isSubscribed: null,
    orgName: null,
    userFound: false,
}

const Unsubcribe = (localState = initialState, action) => {
    switch (action.type) {

        case SET_SUBSCRIBER_DETAILS: {
            console.log("It's working")
            return {
                ...localState,
                userFound: false,
                email: null,
                id: null,
                orgName: null,
                subscriptionStatus: null,
            }
        }
        case SET_EMAIL: {
            const { email, id, orgName, subscriptionStatus } = action.payload;
            return {
                ...localState,
                email: email,
                id: id,
                orgName: orgName,
                subscriptionStatus: subscriptionStatus,
                isSubscribed: null,
                userFound: true,
            }
        }

        case SET_SUBSCRIBE_STATUS: {
            const status = action.payload;
            console.log("status: ", status)
            return {
                ...localState,
                isSubscribed: status,
                subscriptionStatus: null,
            }
        }

        default: {
            return localState
        }
    }
}

export default Unsubcribe;