import { SNACKBAR_MESSAGE } from "./constants";

export const setSnackbarStatus = (status) => ({
  type: SNACKBAR_MESSAGE,
  payload: status,
});

export const showSnackbarStatus = (status) => (dispatch) => {
  dispatch(setSnackbarStatus(status));
  setTimeout(() => {
    dispatch(setSnackbarStatus());
  }, 4000);
};

export const snackbar = (status) => (dispatch) => {
  dispatch(setSnackbarStatus(status));
  alert('Workorder Created Sucessfully')
  window.location.href = '/app/customer/workorders'
}