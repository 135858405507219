import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SidebarData from "./SidebarData"
import LeaveTrackerSidebarData from "./LeaveTrackerSidebar";
import PayRollSidebarData from "./PayRollSidebarData";
import ProjectsSidebarData from "./ProjectsSidebarData";
import withRouter from "../../components/Common/withRouter";
import TimeSheetsSidebarData from "./TimeSheetsSidebarData";

const Sidebar = (props) => {
  const ref = useRef();
  const [sidebarItems, setSidebarItems] = useState(SidebarData);
  const [acitiveState, setActiveState] = useState();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    // Rest of the activation logic...
  }, []);

  const removeActivation = (items) => {
    // Removal of activation logic...
  };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const params = pathName.split('/')[1]
    console.log("params: ", params);
    setActiveState(params);
    const fullPath = `/${params}`;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu-item");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);


    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu-item");
    activeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    // Scroll element logic...
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu-item">
              {sidebarItems.map((item, key) => (
                <React.Fragment key={key}>
                  {item.isMainMenu ? (
                    <li className="menu-title">{props.t(item.label)}</li>
                  ) : (
                    <li key={key}>
                      <Link
                        to={item.url ? item.url : "/#"}
                        className={
                          item.issubMenubadge || item.isHasArrow
                            ? ""
                            : "has-arrow"
                        }
                      >
                        <div className={item.url === `/${acitiveState}` ? "border-start border-5 border-success px-1" : "px-1"}>
                          <i
                            className={item.icon}
                            style={{ marginRight: "5px" }}
                          ></i>

                          {item.issubMenubadge && (
                            <span
                              className={"badge rounded-pill float-end " + item.bgcolor}
                            >
                              {" "}
                              {item.badgeValue}{" "}
                            </span>
                          )}
                          <span>{props.t(item.label)}</span>
                        </div>
                      </Link>
                      {item.subItem && (
                        <ul className="sub-menu">
                          {item.subItem.map((item, key) => (
                            <li key={key}>
                              <Link
                                to={item.link}
                                className={item.subMenu && "has-arrow waves-effect"}
                              >
                                {props.t(item.sublabel)}
                              </Link>
                              {item.subMenu && (
                                <ul className="sub-menu">
                                  {item.subMenu.map((item, key) => (
                                    <li key={key}>
                                      <Link to="#">{props.t(item.title)}</Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

const mapStateToProps = (state) => ({
  // currentProfile: state.login.currentProfile,
  // isAuth: state.login.isAuth,
});

export default connect(mapStateToProps, null)(withTranslation()(withRouter(Sidebar)));
