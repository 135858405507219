import React, { } from "react";
import { Link, Navigate } from "react-router-dom";
import { Button, Card, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import visionware_logo from "../../assets/images/Visionware-Logo-R.png";
import logoicon from "../../assets/images/visionwareicon.png";
import ProfileMenu from "./ProfileMenu";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { logoutUser } from "../../store/Auth/Login/actions";

const mapStateToProps = (state) => ({
  profile: state.login.profile,
  isAuth: state.login.isAuth,
})

const mapDispatchToProps = (dispatch) => ({
  logOutAction: () => dispatch(logoutUser()),
})

const Header = (props) => {
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  // console.log("profile: ", props.profile)
  return !props.isAuth ? <Navigate to='/login' /> : (
    <>
      <Card color="success" className="rounded-0 sticky-top" >
        <div className=" d-flex justify-content-between">
          <div className="d-flex ">
            <div className="navbar-brand-box text-center">
              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoicon} alt="logo-sm-light" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={visionware_logo} alt="logo-light" height="30" width="150" />
                </span>
              </Link>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
                onClick={() => {
                  tToggle();
                }}
              >
                <i className="fa fa-bars align-middle text-light"></i>
              </button>
            </div>
          </div>
          <div className="fs-4 p-3 text-light">
            Mass Mailer Portal
          </div>
          <div>
            <UncontrolledDropdown>
              <DropdownToggle color="success" className="px-4 pt-3 pb-3">
                <Avatar round size='40' name={props.profile && props.profile.displayName} />
              </DropdownToggle>
              <DropdownMenu className="mx-2 ddWidth" >
                <ProfileMenu />
                {/* <Enterprises /> */}
                <div className="p-2 px-3">
                  <Button color="danger" size="sm" onClick={props.logOutAction}>
                    Logout
                  </Button>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Card>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
