import { combineReducers } from "redux";

// // Front
import Layout from "./layout/reducer";
import Email from "./Email/reducers"

// // Authentication
import forgetPassword from "./Auth/ForgotPassword/reducers";
import login from "./Auth/Login/reducers";
import resetPassword from "./Auth/ResetPassword/reducers";
import dashboard from "./Dashboard/reducers";
import contacts from "./ContactList/reducers"
import profile from "./UserProfile/reducers";
// import account from "./auth/register/reducer";
// import users from "./users/reducer"
// import userdetails from "../store/userdetails/reducer";
// import zones from "../store/zones/reducer"
import form from "../store/Form/reducers"

const rootReducer = combineReducers({
    Layout,
    Email,
    forgetPassword,
    login,
    resetPassword,
    dashboard,
    contacts,
    profile,
    //   account,
    //   users,
    //   userdetails,
    //   zones,
    form,
});

export default rootReducer;
