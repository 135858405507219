import { SET_ENTERPRISES, SET_USERPROFILE } from "./constants"


const initialState = {
    userProfile: [],
    enterprises: {}
}

const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERPROFILE: {
            const { userProfile } = action.payload;
            return {
                ...state,
                //eslint-disable-next-line
                userProfile: userProfile,
            }
        }

        case SET_ENTERPRISES: {
            const { enterprises } = action.payload;
            console.log("enterprises : ", enterprises);
            return {
                ...state,
                //eslint-disable-next-line
                enterprises: enterprises,

            }
        }

        default:
            return state
    }
}

export default userProfileReducer;