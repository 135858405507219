/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Label,
    FormGroup,
    CardTitle,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Form,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormValidator from '../../helper/formValidator';
import { clearForm, updateForm } from '../../store/Form/actions';
// import { updateForm } from '../../store/core/form/actions';
import { clearResetPasswordError, resetPassword } from '../../store/Auth/ResetPassword/actions';
import VWMessage from '../../components/VWMessage/VWMessage';
import VWButton from '../../components/VWButton/VWButton';

const ResetPassword = (props) => {
    const [isPasswordsNotEqual, setIsPasswordNotEqual] = useState(false);
    const params = useParams();
    const { code } = params;
    const encodedCode = encodeURIComponent(code);
    console.log("code:", code);
    console.log("decodedCode:", encodedCode);

    useEffect(() => {
        props.clearResetPasswordError();
        props.clearFormAction()
    }, [])

    const validateOnChange = (event) => {
        const input = event.target;
        const formData = input.form;
        const { value } = input;
        const { updateFormAction, form } = props;

        const result = FormValidator.validate(input);
        updateFormAction({
            [formData.name]: {
                ...form[formData.name],
                [input.name]: value,
                errors: {
                    ...(form[formData.name] && form[formData.name].errors),
                    [input.name]: result,
                },
            },
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { resetPasswordAction, form } = props;

        resetPasswordAction(encodedCode, form.formResetPassword && form.formResetPassword.password, form.formResetPassword && form.formResetPassword.confirmPassword, form.formResetPassword && form.formResetPassword.email);
    };

    const onBlur = () => {
        const { form } = props;
        setIsPasswordNotEqual(
            form &&
            form.formResetPassword &&
            form.formResetPassword.password !== form.formResetPassword.confirmPassword,
        );
    };

    const hasError = (formName, inputName, method) => {
        const { form } = props;
        return (
            form[formName] &&
            form[formName].errors &&
            form[formName].errors[inputName] &&
            form[formName].errors[inputName][method]
        );
    };
    console.log("errorMessage:", props.errorMessage)
    console.log("updateFormAction:", props.form.formResetPassword)
    console.log("Email Length:", props.form &&
        props.form.formResetPassword && props.form.formResetPassword.email &&
        props.form.formResetPassword.email.length)
    return (
        <div className="loginBox">
            <Container fluid className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col lg="12" className="loginContainer">
                        <Card>
                            <CardBody className="p-4 m-1">
                                <div className="text-center">
                                    <CardTitle tag="h4" className="mt-2">
                                        Reset Password
                                    </CardTitle>
                                </div>
                                <Form className="mt-3" name="formResetPassword">
                                    {props.errorMessage && (
                                        <VWMessage
                                            type="danger"
                                            defaultMessage={props.errorMessage}
                                            baseClassName="alert"
                                        ></VWMessage>
                                    )}
                                    {props.status && (
                                        <VWMessage
                                            type="success"
                                            defaultMessage={props.status}
                                            baseClassName="alert"
                                        ></VWMessage>
                                    )}
                                    <FormGroup>
                                        <Label htmlFor="name">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            className="border-right-0"
                                            placeholder="Enter email address"
                                            invalid={
                                                hasError('formResetPassword', 'email', 'required') ||
                                                hasError('formResetPassword', 'email', 'email')
                                            }
                                            onChange={validateOnChange}
                                            data-validate='["required", "email"]'
                                            value={
                                                props.form &&
                                                    props.form.formResetPassword &&
                                                    props.form.formResetPassword.email
                                                    ? props.form.formResetPassword.email
                                                    : ''
                                            }
                                        />

                                        {props.form &&
                                            props.form.formResetPassword &&
                                            props.form.formResetPassword.email &&
                                            props.form.formResetPassword.email.length > 0 ?
                                            hasError('formResetPassword', 'email', 'email') && (
                                                <span className="invalid-feedback">Please enter valid email</span>
                                            ) : hasError('formResetPassword', 'email', 'required') && (
                                                <span className="invalid-feedback">Email is required</span>
                                            )}

                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="name">Password</Label>
                                        <Input
                                            type="password"
                                            id="id-password"
                                            name="password"
                                            className="border-right-0"
                                            placeholder="Password"
                                            invalid={
                                                hasError('formResetPassword', 'password', 'required') || isPasswordsNotEqual
                                            }
                                            onBlur={onBlur}
                                            onChange={validateOnChange}
                                            data-validate='["required"]'
                                            value={
                                                props.form &&
                                                    props.form.formResetPassword &&
                                                    props.form.formResetPassword.password
                                                    ? props.form.formResetPassword.password
                                                    : ''
                                            }
                                        />
                                        <span className="invalid-feedback">
                                            {isPasswordsNotEqual ? '' : 'Password is required'}
                                        </span>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="email">Confirm Password</Label>
                                        <Input
                                            type="password"
                                            id="id-confirmpassword"
                                            name="confirmPassword"
                                            className="border-right-0"
                                            placeholder="Confirm password"
                                            invalid={
                                                hasError('formResetPassword', 'confirmPassword', 'required') ||
                                                isPasswordsNotEqual
                                            }
                                            onBlur={onBlur}
                                            onChange={validateOnChange}
                                            data-validate='["required"]'
                                            value={
                                                props.form &&
                                                    props.form.formResetPassword &&
                                                    props.form.formResetPassword.confirmPassword
                                                    ? props.form.formResetPassword.confirmPassword
                                                    : ''
                                            }
                                        />
                                        <span className="invalid-feedback">
                                            {isPasswordsNotEqual
                                                ? 'The confirm password must  same as password.'
                                                : 'Confirm password is required'}
                                        </span>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className='d-flex justify-content-center'>
                                            <VWButton
                                                messageKey="resetButton"
                                                defaultMessage="Reset"
                                                // isLoading={props.isPending}
                                                // isDisabled={props.isPending}
                                                buttonType="success"
                                                buttonLength="full"
                                                baseClassName="btn"
                                                className="mt-3"
                                                onClick={onSubmit}
                                            ></VWButton>
                                        </div>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    form: state.form,
    isAuth: state.login.isAuth,
    isPending: state.resetPassword.isPending,
    errorMessage: state.resetPassword.errorMessage,
    status: state.resetPassword.status,
});

const mapDispatchToProps = (dispatch) => ({
    updateFormAction: (encodedCode) => dispatch(updateForm(encodedCode)),
    clearFormAction: () => dispatch(clearForm()),
    resetPasswordAction: (encodedCode, password, confirmPassword, email) => dispatch(resetPassword(encodedCode, password, confirmPassword, email)),
    clearResetPasswordError: () => dispatch(clearResetPasswordError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
