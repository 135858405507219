const SidebarData = [

    {
        label: "Dashboard",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/dashboard",
    },
    {
        label: "Contact List",
        icon: "mdi mdi-account-box-multiple-outline",
        isHasArrow: true,
        url: "/contactlist",
    },

]


export default SidebarData;



