import React, { useEffect } from "react";
// import { Navigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
// import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { getEmail, setUnsubscribe } from "../../store/Email/actions";
import { Navigate } from "react-router-dom";


const mapStateToProps = (state) => ({
    email: state.Email.email,
    id: state.Email.id,
    orgName: state.Email.orgName,
    userStatus: state.Email.userFound,
    subscribeStatus: state.Email.subscriptionStatus,
    isSubscribed: state.Email.isSubscribed,
})

const mapDispatchToProps = (dispatch) => ({
    getEmail: (id) => dispatch(getEmail(id)),
    unsubscribe: (id) => dispatch(setUnsubscribe(id)),
})

const Unsubcribe = (props) => {

    const url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const Id = urlParams.get('v');
    console.log("Id: ", Id);

    useEffect(() => {
        if (Id) {
            props.getEmail(Id)
        }
    }, [])


    const handleUnsubscribe = () => {
        const { id } = props;
        console.log("id: ", id)
        props.unsubscribe(Id);
    }

    console.log("isSubscribed: ", props.subscribeStatus)
    console.log("userStatus: ", props.userStatus)
    console.log("email: ", props.email)
    console.log("orgName: ", props.orgName)
    return (
        <div style={{ backgroundColor: "#ebedf0", fontSize: "20px" }} >
            {!Id ? <Navigate to="/" /> :
                props.userStatus === false ?
                    <div style={{ paddingTop: "10%" }} className="vh-100">
                        <div className="p-4 text-center">
                            <h3 className="text-danger"> We can not find the user.</h3>
                        </div>
                    </div> : props.subscribeStatus === "OPTOUT" ?
                        <div className="p-4 vh-100">
                            <div className="pt-4 d-grid justify-content-center">
                                <div className="pb-4 d-grid justify-content-center">
                                    <h2 style={{ textTransform: "uppercase" }}>{props.orgName}</h2>
                                </div>
                                <h4>You are already unsubscribed.</h4><br />
                                {/* <Label className="text-left">Email</Label> */}
                                <Input style={{ maxWidth: "400px" }} value={props.email} disabled ></Input><br />
                            </div>
                        </div>
                        :
                        props.isSubscribed ?
                            <div className="p-4 vh-100">
                                <div className="pt-4 d-grid justify-content-center">
                                    <div className="pb-4 d-grid justify-content-center">
                                        <h2 style={{ textTransform: "uppercase" }}>{props.orgName}</h2>
                                    </div>
                                    <h4>You have been successfully unsubscribed from our newsletter emails.</h4><br />
                                </div>
                            </div>
                            : < div className=" vh-100 d-grid justify-content-center" >
                                <div className="p-4 ">
                                    <div className="pb-4 d-grid justify-content-center">
                                        <h2 style={{ textTransform: "uppercase" }}>{props.orgName}</h2>
                                    </div>
                                    <h4>Please confirm to unsubscribe from these email notifications.</h4><br />


                                    {/* {isPending ?

                                    <div>
                                        <ReactLoading type="bars" color="#ff3d60"
                                            height={100} width={75} />
                                    </div>
                                    :  */}

                                    {/* <Label>Email</Label> */}
                                    <Input style={{ maxWidth: "500px" }} value={props.email} disabled></Input><br />
                                    <div className="d-flex justify-content-center">
                                        < Button className="p-2  btn-lg" color="danger" onClick={handleUnsubscribe}>Unsubscribe</Button>
                                    </div>
                                    {/* } */}
                                </div>

                            </div>
            }
        </div >)
}

export default connect(mapStateToProps, mapDispatchToProps)(Unsubcribe);