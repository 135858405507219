import agent from "../../services/agent";
// import { clearForm } from '../../core/form/actions';
import { showSnackbarStatus } from '../../snackbar/actions';
import { SET_FORGOT_PASSWORD_CONFIRMATION_STATUS } from './constants';

export const setForgotPasswordConfirmationStatus = (status) => ({
    type: SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
    payload: status,
});

export const forgotPassword = (userName, email) => (dispatch) => {
    console.log("username:", userName, "Email:", email)
    dispatch(setForgotPasswordConfirmationStatus('INPROGRESS'));
    agent.Auth.forgotPassword(userName, email)
        .then(() => {
            console.log("Email sent successfully")
            dispatch(setForgotPasswordConfirmationStatus('EMAIL_SENT'));
            dispatch(showSnackbarStatus("EMAIL SENT SUCCESSFULLY"));
            // dispatch(clearForm());
        })
        .catch(() => {
            dispatch(setForgotPasswordConfirmationStatus('INVALID_EMAIL'));
            dispatch(showSnackbarStatus("INVALID USERNAME OR EMAIL"));
        });
};