/* eslint-disable camelcase */
import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { configureStore } from "../store";
// import { USER_LOGOUT } from "../auth/login/actionTypes";

const superagent = superagentPromise(_superagent, Promise);

const handleErrors = async (err) => {
  if (err && err.status === 401) {
    const store = configureStore();
    // await store.dispatch({ type: USER_LOGOUT });
    localStorage.setItem('reduxState', undefined);
  }
};

const responseBody = (res) => res.body;
// const apiUrl = ""
// const apiUrl = "http://mmailer-api.visionwaretech.com"
const apiUrl = window.__ENV && window.__ENV.apiUrl;
const loginUrl = window.__ENV && window.__ENV.loginUrl;
const mmUrl = window.__ENV && window.__ENV.mmUrl;
const requests = {
  post: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      // .then(responseBody),
      .then((responseBody) => {
        const res = responseBody.text
        return res
      }),
  postData: (url, body) =>
    superagent
      .post(url, body)
      // .set("Content-Type", "application/x-www-form-urlencoded")
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .then(responseBody),
  getWithToken: (url, token) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  getWithoutToken: (url) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      //.set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  postWithToken: (url, body, token) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
  deleteWithToken: (url, token) =>
    superagent
      .del(url, token)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
  putWithToken: (url, body, token) =>
    superagent
      .put(url, body, token)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .set("Authorization", `Bearer ${token}`)
      .then(responseBody),
};
const Auth = {

  // getSubscriptionStatus: () => {
  //   return requests.getWithoutToken(`${apiUrl}/odata/MailerContactList`);
  // },

  getEmail: (id) => {
    return requests.getWithoutToken(`${apiUrl}/api/Subscription/getemail?id=${id}`);
  },

  setUnsubscribe: (Id) => {
    console.log("Id: ", Id)
    const postId = {
      id: Id,
    }
    return requests.post(`${apiUrl}/api/Subscription/updateStatus`, postId);
  },

  login: (userName, password) => {
    const formData = { "username": userName, "password": password };
    console.log("formData:", formData);
    return requests.postData(`${loginUrl}/api/Auth`, formData)
  },

  getCurrentUser: (accessToken) => {
    return requests.getWithToken(`${loginUrl}/api/Auth/UserProfile`, accessToken);

  },

  forgotPassword: (username, emailId) => {
    return requests.postData(`${loginUrl}/api/user/forgotpassword`, {
      username,
      emailId,
    });
  },

  resetPassword: (code, password, confirmPassword, email) => {
    // const { password, confirmPassword, email } = data;
    console.log("Code:", code)
    return requests.postData(`${loginUrl}/api/user/resetpassword`, {
      password,
      confirmPassword,
      code,
      emailId: email,
    });
  },

  getUsersCount: (accessToken) => {
    console.log("accessToken: ", accessToken)
    return requests.getWithToken(`${mmUrl}/api/Profile/dashboard`, accessToken)
  },

  getContactList: (accessToken, page, pageSize, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus) => {
    console.log("orgName: ", orgName)
    // if (!searchOrgName) {
    //   searchOrgName = orgName;
    // }
    let url = `${mmUrl}/odata/MailerContactList?$count=true&$skip=${(page - 1) * pageSize}&$top=${pageSize}`;
    console.log("searchId: ", searchId);
    console.log("sortDirection", sortDirection, "sortOrder", sortOrder)

    if (sortDirection && sortOrder) {
      url += `&$orderby=${sortOrder} ${sortDirection}`;
    }

    if (searchId || searchEmail || searchOrgName || searchSubscriptionStatus || orgName || paramsSubscriptionStatus) {
      url += `&$filter=`;
      const filterArray = [];

      if (orgName) {
        filterArray.push(`orgName eq '${orgName}'`);
      }
      if (searchId) {
        if (searchId.toLowerCase() === 'na') {
          filterArray.push('Id eq null');
        } else {
          filterArray.push(`contains(Id,'${searchId}')`);
        }
      }
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'na') {
          filterArray.push('Email eq null');
        } else {
          filterArray.push(`contains(Email,'${searchEmail}')`);
        }
      }
      if (searchOrgName) {
        if (searchOrgName.toLowerCase() === 'na') {
          filterArray.push('OrgName eq null');
        } else {
          filterArray.push(`contains(OrgName,'${searchOrgName}')`);
        }
      }
      if (searchSubscriptionStatus || paramsSubscriptionStatus) {
        const status = searchSubscriptionStatus ? searchSubscriptionStatus : paramsSubscriptionStatus
        if (status.toLowerCase() === 'na') {
          filterArray.push('SubscriptionStatus eq null');
        } else {
          filterArray.push(`contains(SubscriptionStatus,'${status.toUpperCase()}')`);
        }
      }

      console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      console.log(url)


    }
    return requests.getWithToken(url, accessToken)
  },

  exportContactList: (accessToken, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus) => {

    let url = `${mmUrl}/Export`;
    console.log("paramsSubscriptionStatus: ", paramsSubscriptionStatus);
    console.log("sortDirection", sortDirection, "sortOrder", sortOrder)

    // if (!searchOrgName) {
    //   searchOrgName = orgName;
    // }

    if (sortDirection && sortOrder) {
      url += `?$orderby=${sortOrder} ${sortDirection}`;
    }

    if (searchId || searchEmail || searchOrgName || searchSubscriptionStatus || orgName || paramsSubscriptionStatus) {
      url === `${mmUrl}/Export` ? url += `?$filter=` : url += `&$filter=`
      const filterArray = [];

      if (orgName) {
        filterArray.push(`orgName eq '${orgName}'`);
      }

      if (searchId) {
        if (searchId.toLowerCase() === 'na') {
          filterArray.push('id eq null');
        } else {
          filterArray.push(`contains(id,'${searchId}')`);
        }
      }
      if (searchEmail) {
        if (searchEmail.toLowerCase() === 'na') {
          filterArray.push('email eq null');
        } else {
          filterArray.push(`contains(email,'${searchEmail}')`);
        }
      }
      if (searchOrgName) {
        if (searchOrgName.toLowerCase() === 'na') {
          filterArray.push('orgName eq null');
        } else {
          filterArray.push(`contains(orgName,'${searchOrgName}')`);
        }
      }
      if (searchSubscriptionStatus || paramsSubscriptionStatus) {
        const status = searchSubscriptionStatus ? searchSubscriptionStatus : paramsSubscriptionStatus;
        if (status.toLowerCase() === 'na') {
          filterArray.push('subscriptionStatus eq null');
        } else {
          filterArray.push(`contains(subscriptionStatus,'${status.toUpperCase()}')`);
        }
      }

      console.log("filterArray", filterArray);
      let searchTerm = "";
      if (filterArray.length > 0) {
        searchTerm = filterArray.join(' and ');
      }
      console.log("searchterm:", searchTerm);
      if (searchTerm.length > 0)
        url += `${searchTerm}`;
      console.log(url)


    }
    return requests.getWithToken(url, accessToken)
  },
};


export default { Auth };