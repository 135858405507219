import { getToken } from "../../helper/common";
import agent from "../services/agent";
import { SET_ENTERPRISES, SET_USERPROFILE } from "./constants";

export const setUserProfile = (userProfile) => ({
    type: SET_USERPROFILE,
    payload: { userProfile }

});

export const setEnterprises = (enterprises) => ({
    type: SET_ENTERPRISES,
    payload: { enterprises }

});

export const getUserProfile = () => (dispatch) => {
    getToken().then((accessToken) => {
        console.log("Access Token:", accessToken);

        agent.Auth.getCurrentUser(accessToken)
            .then((userProfile) => {
                console.log("User Profile :", userProfile);
                dispatch(setUserProfile(userProfile));
            })
    })
}
