import React from "react";
// import { Navigate } from "react-router-dom";
import Unsubcribe from "../Pages/Unsubscribe E-mail/unsubscribeEmail"
import Home from "../Pages/Unsubscribe E-mail/home";
import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Contact Management/dashboard";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";
import ContactList from "../Pages/Contact Management/contactList";
import UserProfile from "../Pages/Users/UserProfile";

const authProtectedRoutes = [
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/contactlist', element: <ContactList /> },
    { path: '/profile', element: <UserProfile /> }
];

const publicRoutes = [
    {
        path: '/',
        // element: <BlankLayout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/unsubscribe', element: <Unsubcribe /> },
            { path: '/login', element: <Login /> },
            { path: '/recoverpwd', element: <ForgotPassword /> },
            { path: 'resetpassword/:code', element: <ResetPassword /> },
        ],
    },
];

export { authProtectedRoutes, publicRoutes };
