import { SET_USERS_COUNT } from "./constants";

const initialState = {
    subscriberCounts: null,
    unsubscriberCounts: null,
    orgName: null,
    totalUsers: null
};

export default (localState = initialState, action) => {
    switch (action.type) {
        case SET_USERS_COUNT: {
            const { count } = action.payload;
            // console.log("count: ", count)
            const { optInCount, optOutCount, orgName, totalContacts } = count;
            // console.log("totalContacts: ", totalContacts)
            return {
                ...localState,
                subscriberCounts: optInCount,
                unsubscriberCounts: optOutCount,
                orgName: orgName,
                totalUsers: totalContacts
            }
        }
        default: {
            return localState;
        }
    }
};
