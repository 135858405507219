/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import Avatar from 'react-avatar';
import { User } from 'react-feather';
// import user1 from '../../assets/images/users/user1.jpg';

const ProfileMenu = ({ currentProfile }) => {
    console.log("currentProfile:", currentProfile)
    return (
        <div>
            <div className="d-flex gap-3 p-3 border-bottom pt-2 align-items-center">
                <Avatar name={currentProfile && currentProfile.displayName} round size='40' />
                <span>
                    <h5 className="mb-0">{currentProfile.displayName}</h5>
                    <small>{currentProfile.role}</small>
                </span>
            </div>

            <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to='/profile'
            >
                <DropdownItem className="px-4 py-3 d-flex">
                    <User size={20} />
                    &nbsp;
                    <div>My Profile</div>
                </DropdownItem>
            </Link>

            <DropdownItem divider />
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentProfile: state.login.profile,
    form: state.form,
});

export default connect(mapStateToProps)(ProfileMenu);
