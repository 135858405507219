/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';
import { Label, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const pageSizes = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
]
const VWPagination = ({ pager, setPage }) => {

  return (
    <div className="d-flex flex-row-reverse">
      <Pagination className="pagination-sm">
        <PaginationItem disabled={pager.currentPage === 1}>
          <PaginationLink onClick={() => setPage(1)} previous />
        </PaginationItem>
        <PaginationItem disabled={pager.currentPage === 1}>
          <PaginationLink onClick={() => setPage(pager.currentPage - 1)}>‹</PaginationLink>
        </PaginationItem>
        {pager.pages &&
          pager.pages.map((page, i) => (
            <PaginationItem active={pager.currentPage === page} key={i}>
              <PaginationLink onClick={() => setPage(page)}>{page}</PaginationLink>
            </PaginationItem>
          ))}
        <PaginationItem disabled={pager.currentPage === pager.totalPages}>
          <PaginationLink onClick={() => setPage(pager.currentPage + 1)}>›</PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={pager.currentPage === pager.totalPages}>
          <PaginationLink onClick={() => setPage(pager.totalPages)} next />
        </PaginationItem>
      </Pagination>
      {/* <div className='px-4 d-flex gap-4' >
        <Label className='py-2'>Per Page</Label> */}
      {/* <Select
          // style={{ height: '40px', width: '100px' }}
          options={pageSizes}
          defaultValue={pageSizes[0]}
        // onChange={(e) => setPage(e.value)}
        /> */}
      {/* </div> */}
    </div>
  );
};

export default VWPagination;
