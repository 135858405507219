import { getToken } from "../../helper/common"
import agent from "../services/agent"
import { SET_CONTACT_LIST, SET_EXPORT_CONTACT_LIST } from "./constants"

export const setContactList = (contactList) => ({
    type: SET_CONTACT_LIST,
    payload: contactList,
})

export const setExportContactList = (contactList) => ({
    type: SET_EXPORT_CONTACT_LIST,
    payload: contactList,
})

export const getContactList = (page, pageSize, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Auth.getContactList(accessToken, page, pageSize, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus)
            .then((contactList) => {
                // console.log("contactList: ", contactList);
                dispatch(setContactList(contactList))
            })

    })
}
export const exportContactList = (orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus) => (dispatch) => {
    getToken(dispatch).then((accessToken) => {
        agent.Auth.exportContactList(accessToken, orgName, searchId, searchEmail, searchOrgName, searchSubscriptionStatus, sortDirection, sortOrder, paramsSubscriptionStatus)
            .then((contactList) => {
                // console.log("contact Export List: ", contactList)
                dispatch(setExportContactList(contactList))
            })

    })
}